var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"historic"},[_c('section',{staticClass:"content-historic"},[_c('div',{staticClass:"content-historic-itens"},[_c('div',{staticClass:"content-historic-itens-bg"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-historic-itens-header"},[_c('router-link',{staticClass:"btn-return",attrs:{"to":"/"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"9.586","height":"10.582","viewBox":"0 0 9.586 10.582"}},[_c('path',{attrs:{"d":"M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z","transform":"translate(5.256 12.867)"}})]),_c('p',[_vm._v("volver")])]),_c('h1',{staticClass:"title"},[_vm._v(" Historial de campañas ")])],1)])]),_c('div',{staticClass:"content-historic-itens-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._l((_vm.campaigns.data),function(campaign,key){return _c('div',{key:key,staticClass:"col-12 col-md-6 col-lg-4"},[_c('router-link',{staticClass:"content-card-historic",attrs:{"to":{name: 'campaign', params: {
                                    slug: campaign.slug
                                }}}},[_c('div',{staticClass:"content-card-historic-image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":campaign.image_path,"alt":"image historic campaigns","title":"image historic campaigns"}})]),_c('div',{staticClass:"content-card-historic-div"},[_c('div',{staticClass:"content-card-historic-div-header"},[_c('h2',[_vm._v(_vm._s(campaign.name))]),_c('p',[_vm._v(_vm._s(_vm._f("moment")(campaign.date_start_at,'DD/MM/YYYY'))+" - "+_vm._s(_vm._f("moment")(campaign.date_end_at,'DD/MM/YYYY')))])]),_c('div',{staticClass:"content-card-historic-div-text"},[_c('p',{domProps:{"innerHTML":_vm._s(campaign.description)}})]),_c('p',{staticClass:"link-consult"},[_vm._v("consultar")])])])],1)})],2),_c('div',{staticClass:"row mt-2"},[(Object.keys(_vm.campaigns).length && _vm.campaigns.last_page > 1)?_c('div',{staticClass:"col-sm-12",attrs:{"align":"center"}},[_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination"},[_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",class:{
                                        isDisabled: (!(_vm.campaigns.current_page > 1))
                                    },on:{"click":function($event){return _vm.setPage(_vm.campaigns.current_page - 1)}}},[_vm._v("Volver")])]),_vm._l((_vm.campaigns.last_page),function(page){return _c('div',{key:page},[_c('li',{staticClass:"page-item",class:{active: (_vm.campaigns.current_page == page)}},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(_vm._s(page))])])])}),_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",class:{
                                        isDisabled: ((_vm.campaigns.current_page == _vm.campaigns.last_page))
                                    },on:{"click":function($event){return _vm.setPage(_vm.campaigns.current_page + 1)}}},[_vm._v("Avance")])])],2)])]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }