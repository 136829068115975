<template>
    <main class="historic">
		
<section class="content-historic">
	<div class="content-historic-itens">
		<div class="content-historic-itens-bg">
			<div class="container">
				<div class="content-historic-itens-header">
					<router-link class="btn-return" to="/">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.586" height="10.582" viewBox="0 0 9.586 10.582"><path d="M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z" transform="translate(5.256 12.867)"/></svg>
						<p>volver</p>
					</router-link>
					<h1 class="title">
						Historial de campañas
					</h1>
				</div>
			</div>
		</div>
		<div class="content-historic-itens-body">
			<div class="container">
				<div class="row">
					<template>
						<div class="col-12 col-md-6 col-lg-4" v-for="(campaign, key) in campaigns.data" :key="key">
							<router-link class="content-card-historic"
                                :to="{name: 'campaign', params: {
                                    slug: campaign.slug
                                }}">
								<div class="content-card-historic-image">
									<img class="img-fluid" :src="campaign.image_path" alt="image historic campaigns" title="image historic campaigns"/>
								</div>
								<div class="content-card-historic-div">
									<div class="content-card-historic-div-header">
										<h2>{{campaign.name}}</h2>
                                        <p>{{campaign.date_start_at|moment('DD/MM/YYYY')}} - {{campaign.date_end_at|moment('DD/MM/YYYY')}}</p>
									</div>
									<div class="content-card-historic-div-text">
										<p v-html="campaign.description"></p>
									</div>
									<p class="link-consult">consultar</p>
								</div>
							</router-link>
						</div>
					</template>
				</div>
                <div class="row mt-2">
                    <!--  -->								
                    <div v-if="Object.keys(campaigns).length && campaigns.last_page > 1" class="col-sm-12" align="center">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link"
                                    :class="{
                                        isDisabled: (!(campaigns.current_page > 1))
                                    }"
                                    @click="setPage(campaigns.current_page - 1)">Volver</a>											  
                                </li>

                                <template>
                                    <div v-for="page in campaigns.last_page" :key="page">
                                        <li class="page-item" 
                                        :class="{active: (campaigns.current_page == page)}">
                                            <a class="page-link" @click="setPage(page)">{{page}}</a>
                                        </li>
                                    </div>
                                </template>										
                                
                                <li class="page-item">
                                    <a class="page-link"
                                    :class="{
                                        isDisabled: ((campaigns.current_page == campaigns.last_page))
                                    }" 
                                    @click="setPage(campaigns.current_page + 1)">Avance</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <!--  -->
                </div>
			</div>
		</div>
	</div>
</section>

	</main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            campaigns : {}, 			
			page: 1,           
        }
    },    
    methods:{
        ...mapActions('App', ['loading']),
		async setPage(page){
			this.page = page;

			await this.getList();
		},
        async getList(){
            this.loading(true);
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/list-all',
                params: {
                   page: this.page,
                   limit: 10,
                }             
            }).then(response => response.data.data)
            .then((data) => {  
               this.loading(false); 				
               this.campaigns = data;               
            })          
        },        
    },
    async mounted(){        		
        await this.getList();
    }
}
</script>